

#auth-page{
    display: flex;
}

.auth-right-container{
    width: 75vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
    padding: 2rem;
    background-color: #fffdfa;
    position: relative;
    overflow: scroll;
  
}

#auth-left-container{
    width: 25vw;
    min-width: 300px;
    background-color: #f1f1f1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url('../../Assets/login.webp');
    background-position:center;
    background-size: cover;
  
}

.auth-overlay{
    background-color: rgba(17, 17, 17, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 25vw;
    min-width: 300px;
    z-index: 1;

}


.wrapper {
    position:relative;
  }
  
.auth-input-icon {
    height: 25px;
    position: absolute;
    box-sizing:border-box;
    top:50%;
    right:15px;
    transform: translateY(-50%);
}

.profile-choice{
    width: 694px;
    max-width: 100%;
    min-height: 114px;
    border: 0.6px solid #d4d4d4;
    border-radius: 8px;
    padding: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem ;
    margin-bottom: 2rem;
}

.profile-choice-active{
    width: 694px;
    max-width: 100%;
    min-height: 114px;
    border: 0.6px solid #272838;
    border-radius: 8px;
    padding: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem ;
    margin-bottom: 2rem;
    background-color: #fdfdfd;
}

.profile-choice:hover, .profile-choice-active:hover{
    cursor: pointer;
}

.studio-avatar{
    background-color: #d9d9d9;
    background-image: url('../../Assets/venue.webp');
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    height: 80px;
    width: 80px;
    flex-shrink: 0;
    margin-right: 17px;
}

.teacher-avatar{
    background-color: #d9d9d9;
    background-image: url('../../Assets/login.webp');
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    height: 80px;
    width: 80px;
    flex-shrink: 0;
    margin-right: 17px;
}

.check-circle{
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background-color: #ebebeb;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sign-up-steps{
    border-radius: 24px;
    min-height: 415px;
    width: 413;
    max-width: 100%;
    background-color: white;
    padding: 1rem;
    z-index: 999;
}

.sign-up-step{
    min-height: 68px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
}

.auth-right-contents{
    position: 'absolute';
    left: '10%';
    top: '10%';
    max-width: 90vw;
 
}


@media only screen and (max-width: 1000px) {
    #auth-left-container{
        display: none;
    }
    .auth-right-container{
        width: 100vw;
        padding: 50px 0;
    }
  }