#add-employee-btn{
    border: 2px dashed #a28f89;
    border-radius: 10px;
    text-align: center;
    color: #a28f89;
    height: 60px ;
    width: 95%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
}

.staff-name-container{
    width: 100%;
    border: solid 2px grey;
    margin: 1rem 0;
    border-radius: 3px;
    padding: 0.3rem 1rem;
}
.staff-name-container:hover, .staff-name-selected:hover{
    cursor: pointer;
}

.staff-name-selected{
    width: 100%;
    border: solid 2px green;
    margin: 1rem 0;
    border-radius: 3px;
    padding: 0.3rem 1rem;
}

.staff-name-selected > *{
    color: green;
    font-weight: bold;
}

.rota-staff-img{
    height: 50px;
    width: 50px;
    border-radius: 8px;
    object-position: center;
    object-fit: cover;
    margin-right: 1rem;
}
.date-cell{
    margin: 5px 0;
    height: 100px;
    min-width: 150px;
    border: 1px solid #e0dad7;
}

#rota-table th, #rota-table td{
    padding: 0;

}

#rota-table {
    overflow-x: scroll;
}

#rota-table td:first-child {
    position: sticky;
    background-color: white;
    left: 0;
    z-index: 2;
  }


#rota-table thead tr:nth-child(1) th {
    padding: 1rem;
    position: sticky;
  
    z-index: 3;
    background: #e0dad7;
  }

 


.rota-staff-table-container{
    background-color: #e0dad7;
    min-width: 250px;
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#publish-rota-btn, #delete-rota-btn{
    width: 150px;
    background: unset;
    border: 2px solid #a28f89;
    border-radius: 5px;
    margin: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.5rem;
}

#publish-rota-btn{
    background-color: #a28f89;
    color: white;
}
#delete-rota-btn{
    color: #a28f89;
}

