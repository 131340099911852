#locked{
    position: sticky;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 998;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;

}