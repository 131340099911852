#teacher-dash-content{
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

#teacher-list-container{
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    width: 100%;
   
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
  
   
}

.act-snapshot{
    height: 100px;
    width: 30%;
    min-width: 250px;
    padding: 1rem;
    margin: 1rem;
    display: flex;
    align-items: center;
    background-color: #4d4855;
    background-image: linear-gradient(147deg, #4d4855 0%, #000000 100%);
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: white;
}

.upload-media{
    height: 100px;
    width: 100px;
    border: 2px dashed grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 1.5rem;

}

.upload-media:hover{
    cursor: pointer;
}

.media-container{
    display: flex;
    align-items: center;
    padding: 2rem 0;
    border-top: 1px solid #f1f1f1;
    width: 100%;
    min-height: 100px;
    flex-wrap: wrap;
    overflow: scroll;
}

#setlist{
    width: 600px;
    max-width: 90%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    height: 200px;
    overflow: scroll;
    height: 300px;
    resize: none;

}

.act-img-highlighted{
    height: 100px;
    width: 100px;
    background-color: unset;
    border-radius: 8px;
    border: 3px solid green;
    margin: 0 1rem;
    position: relative;
}

.act-img-preview{
    height: 100px;
    width: 100px;
    background-color: unset;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    margin: 0 1rem;
    position: relative;

}

.act-img-preview:hover, .act-img-highlighted:hover,.act-video:hover{
    cursor: pointer;
}

.act-video{
    height: 100px;
    width: 175px;
    background-color: unset;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    margin: 0 1rem;
    position: relative;

}

.delete-img{
    position: absolute;
    top: -10px;
    right: -10px;
    height: 25px;
    width: 25px;
}