.profile-container{
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-top: 1px solid #f1f1f1;
    width: 100%;
    min-height: 100px;
    flex-wrap: wrap;
}

.profile-container label{
    margin: 1rem 0;
}

#file-input{
    opacity:0;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

#logo-preview{
    height: 100px;
    width: 100px;
    border-radius: 15px;
    background-color: rgb(187, 187, 187);
    box-shadow: rgba(0, 0, 0, 0.65) 0px 5px 15px;
    position: relative;
}

#file-input:hover{
    cursor: pointer;
}

@media only screen and (max-width: 750px) {
    #logo-preview{
        height: 60px;
        width: 60px;
        margin: 1rem
    }
  }