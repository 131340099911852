.business-nav {
    position: relative;
    height: 100vh;
    background-color: #fff;
    width: 256px;
    padding: 3rem 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: 0.5s ease-in-out;
    z-index: 999;
    border-right: 1px solid #f1f1f1;
  }

.business-nav-closed{
    position: relative;
    height: 100vh;
    width: 0;
    background-color: #fff;
    padding: 3rem 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: width 0.5s ease-in-out;
    z-index: 999;
    border-right: 1px solid #f1f1f1;
    transition:  0.5s ease-in-out;

}
.business-nav::before {
    content: "";
    position: absolute;
    width: 2rem;
    height: 100vh;
    top: 0;
    left: 100%;
}

#nav-welcome{
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    justify-content: center;
}

#nav-welcome > p{
    font-size: 0.8rem;
}

#business-avatar{
    height: 50px;
    width: 50px;
    border-radius: 15px;
    background-color: rgb(187, 187, 187);
    box-shadow: rgba(0, 0, 0, 0.65) 0px 5px 15px;}

.business-link-txt{
    font-size: 16px;
    font-family: 'Satoshi', sans-serif;
    color: #211F1F;
}

.business-link-txt-active{
    font-size: 16px;
    font-family: 'Satoshi', sans-serif;
    color: #e0dad7;
}

#business-logout-btn{
    border: unset;
    background: unset;
}

.business-nav-item{
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 10px 0;
    border-radius: 15px;
    transition: 0.5s;
}

.business-nav-item:hover{
    background-color: #f1f1f1;
    border-radius: 15px;
    transition: 0.5s;

}

.business-nav-item-active{
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 10px 0;
    background-color: #4d4855;
    background-image: linear-gradient(147deg, #4d4855 0%, #000000 74%);
    transition: 0.5s;
}

.business-outlet{
    width: calc(100vw - 256px);
    height: 100vh;
    background-color: #fffdfa;
    overflow: scroll;
    position: absolute;
    top: 0;
    left: 256px;
    transition: ease-in-out 0.5s;

}

.business-outlet-expanded{
    width: 100vw;
    height: 100vh;
    background-color: #fffdfa;
    overflow: scroll;
    position: absolute;
    top: 0;
    left: 0;
    transition: ease-in-out 0.5s;
}

.nav-icon{
    height: 30px;
    width: 30px;
    object-fit: contain;
    margin-right: 15px;
    opacity: 1;
}
.icon-filter{
    filter: brightness(0) invert(1);
}

#toggle-nav-btn{
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #4d4855;
    background-image: linear-gradient(147deg, #4d4855 0%, #000000 100%);
    border: unset;
    position: absolute;
    right: -35px;
    top: 10px;
    color: white;
    z-index: 1000;
}

@media only screen and (max-width: 750px) {
    .business-nav {
        padding-bottom: 120px;  /*Prevents mobile bottom section covering settings icon*/
    }
}

